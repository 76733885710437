<template>
  <Loading v-show="show" />
  
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">الاخبار</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li>
                  <router-link to="/">{{ home }}</router-link>
                </li>
                <li>{{ NewsTitle }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="latest-area section-padding" style="padding: 60px 0 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row">
        <div
          v-for="news in News"
          :key="news.postId"
          class="col-lg-6 col-md-12 col-sm-12 col-xs-12 news_top"
        >
          <div class="single-latest-item">
            <div class="single-latest-image">
              <a href="javascript:void(0)"
                ><img
                  class="imagesNews_"
                  v-bind:src="
                    'https://api2.yg-u.net/images/post/' + news.postImage
                  "
                  alt=""
              /></a>
            </div>
            <div class="single-latest-text">
              <h6 style="margin-bottom: 20px">
                <a href="javascript:void(0)">{{ news.postTitle }}</a>
              </h6>
              <p>
                {{ news.postSubTitle }}
              </p>

              <router-link
                class="button-default"
                :to="{
                  name: 'NewsDetails-with-id-And-Type-And-BrnId',
                  params: {
                    type: $route.params.type,
                    id: news.postID,
                    BrnId: news.brnID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      News: [],

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      NewsTitle: "",
      Detials: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    //translate
    if (localStorage.getItem("lang") == "Ar") {
      self.translate = self.ArTranslat;
    } else {
      self.translate = self.EnTranslat;
    }
    self.NewsTitle = self.translate[0]["Home"]["News"];
    self.home = self.translate[0]["HeaderAndFooter"]["Home"];
    self.Detials = self.translate[0]["Home"]["Detials"];

    var bodyFormData1 = new FormData();
    bodyFormData1.append("check", "getPost");
    bodyFormData1.append("Lang", localStorage.getItem("lang"));
    bodyFormData1.append("FbrnId", this.$route.params.BrnId);
    bodyFormData1.append("Type", this.$route.params.type);
    bodyFormData1.append("ParentId", "NTROOT0");
    bodyFormData1.append("PostName", "");
    bodyFormData1.append("Pno", "-1");
    axios({
      method: "post",
      url: "https://api2.yg-u.net/our_team/getPost",
      data: bodyFormData1,
    })
      .then(function (response) {
        self.News = response.data;
        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "/js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);

        setTimeout(() => {
          self.show = false;
          document
            .getElementById("YG_U")
            .setAttribute("style", "display:none !important");
        }, 100);
      })
      .catch(function (response) {
        // eslint-disable-next-line no-console
        console.log("error", response);
      });
  },
};
</script>

<style scoped>
.imagesNews_ {
  width: 236px;
  height: 234px;
}
.news_top {
  margin-top: 50px;
}
</style>
